// import Swiper from 'swiper';
// import 'swiper/swiper.min.css';

export default class Workswiper {
  init(root) {
    this.root = root;
    this.allVideoElements = this.root.querySelectorAll('video');
    this.render();
  }

  render() {
    const swiper = new Swiper(this.root, {
        effect: "cards",
    //   slidesPerView: 'auto',
    //   centeredSlides: true,
    //   spaceBetween: 32,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      on: {
        transitionEnd: () => {
          this.playVideo();
        },
      },
    });

    this.swiper = swiper; // Store swiper instance for reference
  }

  playVideo() {
    // Pause all videos first
    this.pauseAllVideos();

    // Select the video in the active slide and play it
    const videoElement = this.root.querySelector('.swiper-slide-active video');

    if (videoElement) {
      videoElement.play();
    }
  }

  pauseAllVideos() {
    // Pause all videos in the slideshow
    this.allVideoElements.forEach((video) => {
      video.pause();
    });
  }
}
