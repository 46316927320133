export default class YouTubeVideoLoader {
    constructor() {
      this.thumbnail = document.getElementById('thumbnail');
      this.videoIframeContainer = document.getElementById('video-iframe-container');
      this.loadVideoBtn = document.getElementById('load-video-btn');
      this.videoContainer = document.getElementById('video-container');
  
      this.loadVideoBtn.addEventListener('click', this.loadVideo.bind(this));
    }
  
    loadVideo() {
      this.videoIframeContainer.innerHTML = `
        <iframe width="315" height="560"
          src="https://www.youtube.com/embed/Y84nv4t_BQk"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen>
        </iframe>
      `;
  
      this.thumbnail.style.display = 'none';
      this.videoContainer.style.display = 'none';
      this.videoIframeContainer.style.display = 'block';
    }
  }
  