import SmoothScroll from "smooth-scroll"

// Your JS Code goes here
// import Stories from '../components/stories/stories.js'
import Hero from '../components/hero/hero.js'
import Workswiper from '../components/workswiper/workswiper.js'
import YouTubeVideoLoader from '../components/embed/embed.js'

/*let stories = document.querySelectorAll(".story");

stories.forEach(story => {
  let element = new Stories();
  element.init(story)
  element.log()
})*/

let heroEl = document.querySelector(".hero");
let hero = new Hero();
hero.init(heroEl)

// let workswiperEl = document.querySelector(".workswiper");
// let workswiper = new Workswiper();
// workswiper.init(workswiperEl)

let workswiperEls = document.querySelectorAll('.workswiper');
workswiperEls.forEach(el => {
  let workswiper = new Workswiper();
  workswiper.init(el)
})


// import YouTubeVideoLoader from './path/to/YouTubeVideoLoader';

document.addEventListener('DOMContentLoaded', function () {
  const videoLoader = new YouTubeVideoLoader();
});



var scroll = new SmoothScroll('a[href*="#"]');