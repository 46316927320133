//import './style.css'
import gsap from "gsap";
import * as THREE from 'three'
import { lerp } from './../../js/math.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
//import * as dat from 'dat.gui'
let touchDevice = false;
let mouseX = 0.5;
let mouseY = 0.5;
let easedMouseX = 0.5;
let easedMouseY = 0.5;
let animateTape = 0;
const tapes = Array.from(document.querySelectorAll('.tape'));
const tapesContent = Array.from(document.querySelectorAll('.tape-wrapper'));

const profileBG = document.querySelector('.profile-background');
const profileImage = document.querySelector('.profile-image');
const profileObjectsBottom = document.querySelector('.profile-objects-bottom');
const profileObjectsTop = document.querySelector('.profile-objects-top');

tapesContent.forEach((tape, index) => {
    let tapeContent = tape.innerHTML;
    tape.innerHTML = tapeContent + tapeContent + tapeContent;
})
//Debug
//const gui = new dat.GUI()

//Textures
const textureLoader = new THREE.TextureLoader()


// import matcap13 from '../../images/textures/matcaps/13-1.png'
// import matcap03 from '../../images/textures/matcaps/3.png'
// import matcap04 from '../../images/textures/matcaps/4.png'
// import matcap05 from '../../images/textures/matcaps/9.png'

import matcap01 from '../../images/textures/matcaps/plastik-blue.png'
import matcap02 from '../../images/textures/matcaps/wand-matcap.png'
import matcap03 from '../../images/textures/matcaps/plastic-matcap.png'
// import matcap03 from '../../images/textures/matcaps/plastic-matcap-yellow.png'
import matcap04 from '../../images/textures/matcaps/cartoon-yellow2.jpeg'

const matcap1 = textureLoader.load(matcap01)
const matcap2 = textureLoader.load(matcap02)
const matcap3 = textureLoader.load(matcap03)
const matcap4 = textureLoader.load(matcap04)


matcap1.colorSpace = THREE.SRGBColorSpace;
matcap2.colorSpace = THREE.SRGBColorSpace;
matcap3.colorSpace = THREE.SRGBColorSpace;
matcap4.colorSpace = THREE.SRGBColorSpace;

/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

//generates lighting from single sphere jpg:
const material1 = new THREE.MeshMatcapMaterial()
material1.matcap = matcap1

const material2 = new THREE.MeshMatcapMaterial()
material2.matcap = matcap2

const material3 = new THREE.MeshMatcapMaterial()
material3.matcap = matcap3

const material4 = new THREE.MeshMatcapMaterial()
material4.matcap = matcap4

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

mouseX = sizes.width * 0.6;
mouseY = sizes.height * 0.6;

const pixelRatio = window.devicePixelRatio;
let scaleDesktop = 1;
let scaleMobile = 0.5625;

let responsiveScale = scaleDesktop;

if(sizes.width < 480) {
    responsiveScale = scaleMobile;
}

window.addEventListener('resize', () =>
{
    if(sizes.width != window.innerWidth) {
        // Update sizes
        sizes.width = window.innerWidth
        sizes.height = window.innerHeight

        //Update scaling
        if(sizes.width < 480) {
            responsiveScale = scaleMobile;
        } else {
            responsiveScale = scaleDesktop;
        }
        gltfModel.scale.set(0.3 * responsiveScale, 0.3 * responsiveScale, 0.3 * responsiveScale)

        // Update camera
        camera.aspect = sizes.width / sizes.height
        camera.updateProjectionMatrix()

        // Update renderer
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(window.devicePixelRatio)
    }
})


const loader = new GLTFLoader();
let gltfModel;

loader.load('./wntr_logo-3.gltf', (gltf) => {
    gltfModel = gltf.scene;

    gltfModel.scale.set(0.025,0.025,0.025)
    scene.add(gltfModel);

    // Call your function to add materials here
    addMaterialsToModel(gltfModel);

    const targetScale = new THREE.Vector3(0.3, 0.3, 0.3); // Adjust this to your model's actual size
    const animationDuration = 1000; // Adjust the duration as needed
    animateModelScale(targetScale, animationDuration)

});

function addMaterialsToModel(model) {
    model.traverse((node) => {
        if (node.isMesh) {            
            if(node.name == "e-helix" || node.name == "i-dot" || node.name == "i-cylinder") {
                node.material = material1;
            } else if(node.name == "Cylinder" || node.name == "Path" || node.name == "Pyramid_Top_Left" || node.name == "Torus_Right" || node.name == "Sphere_Bottom_Left_White") {
                node.material = material4;
            } else {
                node.material = material2;
            }
        }
    });
}

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 0
camera.position.y = 0
camera.position.z = 4
scene.add(camera)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})

renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    //camera.position.z = Math.cos(elapsedTime * 1.5) * 0.5 + 2
    tapeAnimation();

    // if(!touchDevice) {
        const maxRotation = Math.PI / 8; // Adjust this value for the maximum rotation angle.
        const rotationX = (easedMouseX / window.innerWidth - 0.5) * maxRotation;
        const rotationY = (easedMouseY / window.innerHeight - 0.5) * maxRotation;
        // Apply rotation to the model.
        if(gltfModel) {
            gltfModel.rotation.x = rotationY;
            gltfModel.rotation.y = rotationX;
        }
    // }

    profileParallax();

    easedMouseX = lerp(easedMouseX, mouseX, 0.1);
    easedMouseY = lerp(easedMouseY, mouseY, 0.1);

    // Render
    renderer.render(scene, camera)

    window.requestAnimationFrame(tick)
}

tick()

function handleMouseMove(e) {
    mouseX = e.clientX
    mouseY = e.clientY
    // const maxRotation = Math.PI / 8; // Adjust this value for the maximum rotation angle.
    // Calculate rotation angles based on the event's position.
    // const rotationY = (e.clientX / window.innerWidth - 0.5) * maxRotation;
    // const rotationX = (e.clientY / window.innerHeight - 0.5) * maxRotation;
    // console.log(e.clientX / window.innerWidth - 0.5)

}

function handleTouchMove(e) {
    // Use the first touch point for rotation on touch devices.
    const touch = e.touches[0];
    mouseX = touch.clientX
    mouseY = touch.clientY
    // const maxRotation = Math.PI / 8; // Adjust this value for the maximum rotation angle.
    // const rotationY = (touch.clientX / window.innerWidth - 0.5) * maxRotation;
    // const rotationX = (touch.clientY / window.innerHeight - 0.5) * maxRotation;
    // const rotationY = (easedMouseX / window.innerWidth - 0.5) * maxRotation;
    // const rotationX = (easedMouseY / window.innerHeight - 0.5) * maxRotation;


    // // Apply rotation to the model.
    // if(gltfModel) {
    //     gltfModel.rotation.x = rotationX;
    //     gltfModel.rotation.y = rotationY;
    // }
}

// Check if the device supports touch events and attach the appropriate event listener.
// if ('ontouchstart' in window || navigator.maxTouchPoints) {
//     document.addEventListener('touchmove', handleTouchMove);
//     touchDevice = true;
// } else {
//     document.addEventListener('mousemove', handleMouseMove);
// }

//the other way around: if there is a cursor, always use the cursor instead of touch interactions
const hasCursor = window.matchMedia('(hover: hover)').matches;
// Attach the appropriate event listener based on device capabilities.
if (hasCursor || navigator.maxTouchPoints) {
    if (hasCursor) {
        document.addEventListener('mousemove', handleMouseMove);
    } else {
        document.addEventListener('touchmove', handleTouchMove);
        touchDevice = true;
    }
} else {
    // Fallback for devices without cursor or touch support.
    document.addEventListener('mousemove', handleMouseMove);
}


// Function to animate the scale
function animateModelScale(targetScale, duration) {
    gsap.to(gltfModel.scale, {
        x: targetScale.x,
        y: targetScale.y,
        z: targetScale.z,
        duration: duration / 1000, // Convert milliseconds to seconds
        ease: "expo.out",
        onComplete: () => {
            // Animation is complete, you can perform additional actions here if needed.
        },
    });
    gsap.to(camera.position, {
        z: 2,
        duration: duration / 1000, // Convert milliseconds to seconds
        ease: "expo.out",
    })
}

// Function to animate the tape
function tapeAnimation() {
    // Calculate tapeSpeed based on mouseX and windowWidth
    const tapeSpeed = gsap.utils.mapRange(0, sizes.width, -0.1, 0.1, easedMouseX);

    // Iterate over your tape elements
    tapes.forEach((tape, index) => {
        if(animateTape > 100) {
            animateTape = tapeSpeed;
        } else if(animateTape < 0) {
            animateTape = 100 + (tapeSpeed);
        }
        let tapeWidth = tapesContent[index].offsetWidth;
        //debug.innerHTML = tapeWidth + s.windowWidth
        tapesContent[index].style.transform = "translate3d(-" + gsap.utils.mapRange(0, 100, 0, tapeWidth / 3, animateTape) + "px, -50%, 0)";
    })

    // Update the animation in the Three.js tick function
    animateTape = animateTape + tapeSpeed;
}

function profileParallax() {
    const translateBG = {
        x: gsap.utils.mapRange(0, sizes.width, 2, -2, easedMouseX),
        y: gsap.utils.mapRange(0, sizes.height, 2, -2, easedMouseY)
    };
    const translateImage = {
        x: gsap.utils.mapRange(0, sizes.width, -0.25, 0.25, easedMouseX),
        y: gsap.utils.mapRange(0, sizes.height, -0.25, 0.25, easedMouseY)
    };
    const translateObjectsBottom = {
        x: gsap.utils.mapRange(0, sizes.width, -2, 2, easedMouseX),
        y: gsap.utils.mapRange(0, sizes.height, -2, 2, easedMouseY)
    };
    const translateObjectsTop = {
        x: gsap.utils.mapRange(0, sizes.width, -4, 4, easedMouseX),
        y: gsap.utils.mapRange(0, sizes.height, -4, 4, easedMouseY)
    };
    profileBG.style.transform = "translate3d(" + translateBG.x + "%, " + translateBG.y + "%, 0)";
    profileImage.style.transform = "translate3d(" + translateImage.x + "%, " + translateImage.y + "%, 0)";
    profileObjectsBottom.style.transform = "translate3d(" + translateObjectsBottom.x + "%, " + translateObjectsBottom.y + "%, 0)";
    profileObjectsTop.style.transform = "translate3d(" + translateObjectsTop.x + "%, " + translateObjectsTop.y + "%, 0)";
}